<div class="container">
    <div class="row filter-block mb-3">
        <div class="col-12">
            <div class="row mb-2">
                <div class="col-4">
                    <label>Тип контрагента</label>
                    <Multiselect
                        v-model="selectionValues.partnerTypes"
                        :options="partnerTypes"
                        :label="'name'"
                        placeholder="Выберите из списка"
                        valueProp="id"
                        trackBy="name"
                        mode="tags"
                        :searchable="true"
                    />
                </div>
                <div class="col-3">
                    <label>Филиал</label>
                    <Multiselect
                        v-model="selectionValues.branchCodes"
                        :options="branches"
                        :label="'full_name'"
                        placeholder="Выберите из списка"
                        valueProp="Ref_Key"
                        trackBy="full_name"
                        mode="tags"
                        :searchable="true"
                        :disabled="true"
                    />
                </div>
                <div class="col-2 mt-3">
                    <FormCheckbox
                        v-model="selectionValues.emailExists"
                        label="Наличие email"
                    />
                </div>
            </div>
            <div class="row mb-2 border-dashed">
                <div class="col-12">
                    <div class="row mb-2">
                        <div class="col-12">
                            <label>Классификатор 1С</label>
                            <DropdownTree
                                :mode="'edit'"
                                v-model:classifier="classifier"
                                v-model:selectedItems="selectionValues.classifierItems"
                            />
                        </div>
                    </div>
                    <div class="row mb-2">
                        <FormRadio
                            v-model="selectionValues.classifiersUnionBy"
                            :value="'and'"
                            label="И"
                            name="unionBy"
                            wrapper-class="inline"
                        />
                        <FormRadio
                            v-model="selectionValues.classifiersUnionBy"
                            :value="'or'"
                            label="ИЛИ"
                            name="unionBy"
                            wrapper-class="inline"
                        />
                    </div>
                    <div class="row mb-2">
                        <div class="col-12">
                            <label>Веб классификатор</label>
                            <Multiselect
                                v-model="selectionValues.cultivationTreeNodeIds"
                                :options="cultivationTreeNodes"
                                :label="'name'"
                                placeholder="Выберите из списка"
                                valueProp="id"
                                trackBy="name"
                                mode="tags"
                                :searchable="true"
                            />
                        </div>
                    </div>
                    <div class="row mb-2">
                        <div class="col-6">
                            <label>Минимальный рейтинг по веб-классификатору</label>
                            <Input
                                :placeholder="'Введите число'"
                                v-model="selectionValues.minRating"
                            />
                        </div>
                        <div class="col-6">
                            <label>Макс. кол-во контактных лиц на гостя</label>
                            <Input
                                :placeholder="'Введите число'"
                                v-model="selectionValues.maxGuestContacts"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mb-2">
                <div class="col-3 mt-2">
                    <span>Найдено результатов ({{partnersCount}})</span>
                </div>
                <div class="col-3">
                    <Button
                        type="a"
                        label="Экcпорт CSV"
                        href="#"
                        @click.prevent.stop="downloadFile"
                    />
                </div>
                <div class="col-3">
                    <Button
                        type="a"
                        label="Применить фильтры"
                        href="#"
                        @click.prevent.stop="applyFilters"
                    />
                </div>
            </div>
        </div>
    </div>

    <div class="mb-3">
        <Loader v-if="this.loading"/>
        <Grid
            v-else
            :columns="[
                {
                    name: 'partner_name',
                    label: 'Контрагент',
                    width: 'minmax(20px, 0.50fr)',
                },
                {
                    name: 'partner_full_name',
                    label: 'Контрагент, полное имя',
                    width: 'minmax(50px, 1fr)',
                },
                {
                    name: 'name',
                    label: 'Контактное лицо',
                    width: 'minmax(50px, 1fr)',
                },
                {
                    name: 'first_name',
                    label: 'Контактное лицо, имя',
                    width: 'minmax(50px, 1fr)',
                },
                {
                    name: 'middle_name',
                    label: 'Контактное лицо, отчество',
                    width: 'minmax(50px, 1fr)',
                },
                {
                    name: 'email',
                    label: 'e-mail',
                    width: 'minmax(50px, 1fr)',
                },
                {
                    name: 'partner_external_id',
                    label: 'ID клиента',
                    width: 'minmax(50px, 1fr)',
                },
            ]"
            :data="partnerList"
            :onEdit="undefined"
            :onRemove="undefined"
        >
        </Grid>
    </div>
    <div class="pagination__wrapper">
        <ul class="pagination">
            <li>
                <button class="prev" @click="prevPage" :disabled="pageNumber===0">&#10094;</button>
            </li>
            <template v-for="(number,key) in allPages">
                <li @click="key !== pageNumber ? openPage(key) : ''"
                    v-if="key === 0 || number === allPages || (pageNumber + 7 >= key && pageNumber - 7 <= key)">
                    <button
                        v-bind:class="{'active':pageNumber === key,'side_numbers':key === 0 || number === allPages}">
                        {{ number }}
                    </button>
                </li>
            </template>
            <li>
                <button class="next" @click="nextPage" :disabled="pageNumber">&#10095;</button>
            </li>
        </ul>
    </div>
</div>
