<template src="../templates/dropdown-tree.html"></template>

<script>
import Grid from '@c/Grid';
import FormInput from '@f/Input';
import FormCheckbox from '@f/Check';
import helpers from '@/tools/helpers';
import Loader from '@c/Loader';
import Error from '@c/Error';
import FormSelect from '@f/Select';

export default {
    name: 'DropdownTree',
    components: {
        Grid,
        FormInput,
        FormCheckbox,
        Loader,
        Error,
        FormSelect
    },
    props: {
        classifier: {
            type: Array,
            required: true,
            default() {
                return [];
            }
        },
        selectedItems: {
            type: Array,
            required: true,
            default() {
                return [];
            }
        },
    },
    emits: ['update:selectedItems', 'update:classifier'],
    data() {
        return {
            dropdownVisible: false,
            inputData: {},
            comparison_operators: [
                {title: '= (Равно)', value: 'EQUAL'},
                {title: '≠ (Не равно)', value: 'NOT_EQUAL'},
                {title: '> (Больше)', value: 'MORE'},
                {title: '≥ (Больше либо равно)', value: 'EQUAL_MORE'},
                {title: '< (Меньше)', value: 'LESS'},
                {title: '≤ (Меньше либо равно)', value: 'EQUAL_LESS'},
            ],
            constants: Object.freeze({
                BOOL_TYPE: 'BOOL',
                BOOL_VALUES: {
                    true: 'Да',
                    false: ''
                },
            }),
            columns: [
                {
                    name: 'name',
                    label: 'Показатель',
                    width: '60%',
                },
                {
                    slot_name: 'operation',
                    name: 'operation',
                    label: 'Операция сравнения',
                    width: '30%',
                },
                {
                    slot_name: 'value',
                    name: 'value',
                    label: 'Значение',
                    width: '10%',
                },
            ],
        };
    },
    mounted() {
        document.addEventListener('click', this.handleClickOutsideDropdown);
    },
    beforeUnmount() {
        document.removeEventListener('click', this.handleClickOutsideDropdown);
    },
    methods: {
        updateClassifier(changedItem) {
            this.$nextTick(() => {
                let changedClassifierItems = helpers.deepCopy(this.selectedItems);
                changedClassifierItems = changedClassifierItems.filter(i => (i.itemId !== changedItem.itemId));

                if (true/*changedItem.value.length > 0 && changedItem.operation.length > 0*/) {
                    changedClassifierItems.push(changedItem);
                }

                // находим показатель в дереве по списку всех его родителей, меняем значение
                const changedClassifier = helpers.deepCopy(this.classifier);
                let children = changedClassifier;
                for (let i = 0; i < changedItem.allParents.length; i++) {
                    children = children.find(
                        itemT => itemT.itemId === changedItem.allParents[i]
                    ).children;
                    if (i === changedItem.allParents.length - 1) {
                        children.find(
                            itemT => itemT.itemId === changedItem.itemId
                        ).value = changedItem.value;
                        children.find(
                            itemT => itemT.itemId === changedItem.itemId
                        ).operation = changedItem.operation;
                    }
                }

                this.$emit('update:selectedItems', changedClassifierItems);
                this.$emit('update:classifier', changedClassifier);
            });

        },
        expandTree(tree) {
            return tree.map(item => {
                const result = {...item, isExpanded: true};
                result.children = this.expandTree(result.children);
                return result;
            });
        },
        toggleDropdown() {
            this.dropdownVisible = !this.dropdownVisible;
        },
        removeItem(item) {
            let changedClassifierItems = helpers.deepCopy(this.selectedItems);

            changedClassifierItems = changedClassifierItems.filter(i => (i.itemId !== item.itemId));
            this.$emit('update:selectedItems', changedClassifierItems);


            // находим показатель в дереве по списку всех его родителей, очищаем значение
            const changedClassifier = helpers.deepCopy(this.classifier);
            let children = changedClassifier;
            for (let i = 0; i < item.allParents.length; i++) {
                children = children.find(
                    itemT => itemT.itemId === item.allParents[i]
                ).children;
                if (i === item.allParents.length - 1) {
                    children.find(
                        itemT => itemT.itemId === item.itemId
                    ).value = '';
                    children.find(
                        itemT => itemT.itemId === item.itemId
                    ).operation = '';
                }
            }

            this.$emit('update:classifier', changedClassifier);
        },
        clearItems() {
            this.$emit('update:selectedItems', []);
        },

        handleClickOutsideDropdown(e) {
            const targetElement = this.$refs.dropdown;
            const mainElement = targetElement.closest('.multiselect');

            if (!targetElement.contains(e.target) && !mainElement.contains(e.target)) {
                this.dropdownVisible = false;
            }
        },
    },
};
</script>
