<div class="">
    <div>
        <div class="multiselect">
            <div class="multiselect-input" @click.stop.prevent="toggleDropdown">
                <div class="multiselect-tags">
                    <span v-for="(item, _) in selectedItems">
                        <div class="multiselect-tag">{{ item.name }} <i @click.stop="removeItem(item)"></i></div>
                    </span>
                </div>
                <div class="multiselect-placeholder" v-if="!selectedItems.length" >Укажите параметры</div>
                <a class="multiselect-clear" v-if="selectedItems.length" @click.stop.prevent="clearItems"></a>
            </div>
            <div class="multiselect-options" style="max-height: 320px;" ref="dropdown" v-show="dropdownVisible">
                <Grid
                    v-if="classifier.length"
                    tree-grid
                    id-field="itemId"
                    :columns="columns"
                    :data="classifier"
                    :onEdit="undefined"
                    :onRemove="undefined"
                    :formatters="{}"
                >
                    <template #value="{ data: item }">
                        <FormInput
                            v-if="item.valueType !== constants.BOOL_TYPE"
                            type="number"
                            v-model="item.value"
                            :attributes="{
                                min: 0,
                                step: 1,
                            }"
                            @change="updateClassifier(item)"
                        />
                    </template>
                    <template #operation="{ data: item }">
                        <template v-if="item.level > 0">
                            <FormSelect
                                v-if="item.valueType !== constants.BOOL_TYPE"
                                :options="comparison_operators"
                                v-model="item.operation"
                                @change="updateClassifier(item)"
                            />
                        </template>
                    </template>
                </Grid>
            </div>
        </div>
    </div>
</div>


