<template src="./templates/partner-contacts.html"></template>

<script>
    import Multiselect from '@vueform/multiselect';
    import '../../layouts/default/styles/partners.scss';

    import {
        getPartnerTypes,
        getCultivationTechnologyTree,
        findPartnersContacts,
        downloadPartnerContactsCsv,
        getClassifierItemTree
    } from '@api/partners';

    import {
        getBranches
    } from '@api/layout';

    import Input from '@f/Input';
    import FormCheckbox from '@f/Check';
    import Tree from '@c/Tree';
    import Grid from '@c/Grid';
    import Button from '@f/Button';
    import Loader from '@c/Loader';
    import FormRadio from '@f/Radio/index';
    import DropdownTree from './components/dropdown-tree';


    export default {
        name: 'PartnerContacts',
        components: {
            FormRadio,
            Button,
            Grid,
            Tree,
            Multiselect,
            Input,
            FormCheckbox,
            DropdownTree,
            Loader,
        },
        props: {
            size: {
                type: Number,
                required: false,
                default: 100
            },
        },
        data() {
            return {
                loading: false,
                partnerTypes: [],
                cultivationTreeNodes: [],
                classifier: [],
                branches: [],
                partners: null,
                pageNumber: 0,
                allPages: 0,
                partnersCount: 0,
                partnerList: [],
                selectionValues: {
                    partnerTypes: [],
                    branchCodes: [],
                    emailExists: true,
                    cultivationTreeNodeIds: [],
                    limit: 100,
                    offset: 0,
                    minRating: 10,
                    maxGuestContacts: 2,
                    classifierItems: [],
                    classifiersUnionBy: 'and',
                },
            };
        },
        computed: {},
        watch: {},
        async created() {
            this.branches = await getBranches();
            const types = await getPartnerTypes();
            this.partnerTypes = Object.values(types);
            const tree = await getCultivationTechnologyTree(1);
            this.cultivationTreeNodes = tree.reduce((acc, item) => [...acc, ...item.children], []);
            this.classifier = await getClassifierItemTree();
        },
        methods: {
            async nextPage() {
                this.pageNumber++;
                await this.requestData(this.pageNumber * this.selectionValues.limit);
            },
            async prevPage() {
                this.pageNumber--;
                await this.requestData(this.pageNumber * this.selectionValues.limit);
            },
            async openPage(key) {
                this.pageNumber = key;
                await this.requestData(this.pageNumber * this.selectionValues.limit);
            },
            async requestData(offset) {
                this.loading = true;

                this.selectionValues.offset = offset;
                this.partnerList = await findPartnersContacts(this.selectionValues);
                this.partnersCount = await findPartnersContacts(this.selectionValues, true);
                this.allPages = Math.ceil(this.partnersCount / this.selectionValues.limit);

                this.loading = false;
            },
            async applyFilters() {
                this.pageNumber = 0;
                await this.requestData(0);
            },
            async downloadFile() {
                const data = await downloadPartnerContactsCsv({
                    ...this.selectionValues,
                    offset: undefined,
                    limit: undefined
                });
                this.saveFile(data, 'temp.csv');
            },
            saveFile(data, fileName) {
                const blob = new Blob([data], {type: 'application/csv'});

                const link = document.createElement('a');
                link.href = URL.createObjectURL(blob);
                link.download = fileName;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            },
        }
    };
</script>
<style src="@vueform/multiselect/themes/default.css"></style>
